// import React, { useState } from "react";
// import { Pagination } from "antd";
// import "./style.css";
// import { Col, Row } from "react-bootstrap";
// import CardMenuIcon from "../../assets/svg/CardMenu";
// import "../DetailCard/style.css";

// const CustomTable = ({ data, onRow, selectedRowKey, pagination, width }) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(pagination?.pageSize || 10);

//   const handlePageChange = (page, pageSize) => {
//     setCurrentPage(page);
//     setPageSize(pageSize);
//   };

//   const startIndex = (currentPage - 1) * pageSize;
//   const endIndex = startIndex + pageSize;
//   const paginatedData = data.slice(startIndex, endIndex);

//   const styles = {
//     selectedCard: {
//       backgroundColor: "#daf4e1",
//     },
//   };

//   return (
//     <div>
//       <Row gutter={[16, 16]}>
//         <div
//           className={
//             width > 35
//               ? "detail-card-container"
//               : "detail-card-container-responsive"
//           }
//         >
//           {paginatedData?.map((record, i) => {
//             return (
//               <Col
//                 key={i}
//                 sm={12}
//                 md={6}
//                 lg={width > 35 ? 6 : 12}
//                 className="testCardStyle"
//                 style={
//                   record?.key === selectedRowKey ? styles.selectedCard : null
//                 }
//                 onClick={() => onRow && onRow(record)}
//               >
//                 <Row>
//                   <Col xs={3}>
//                     <p className="testIdNo">Req No.</p>
//                   </Col>
//                   <Col xs={3}>
//                     <p className="testIdNoList">{record?.number}</p>
//                   </Col>
//                   <Col xs={6}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "end",
//                         alignItems: "baseline",
//                       }}
//                     >
//                       <CardMenuIcon width={24} height={24} />
//                     </div>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col xs={3}>
//                     <p className="testIdNo">Service Name</p>
//                   </Col>
//                   <Col xs={9}>
//                     <p className="PersonName">{record?.name}</p>
//                   </Col>
//                 </Row>

//                 <p className="borderline"></p>
//                 <Row>
//                   <Col xs={3}>
//                     <p className="testIdNo">Requester Name</p>
//                   </Col>
//                   <Col xs={9}>
//                     <p className="testIdNoList">
//                       {record?.fullData?.customer?.fullname}
//                     </p>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col xs={3}>
//                     <p className="testIdNo">Emirates ID</p>
//                   </Col>
//                   <Col xs={9}>
//                     <p className="testIdNoDetail">{record?.section}</p>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col xs={3}>
//                     <p className="testIdNo">Status</p>
//                   </Col>
//                   <Col xs={9}>
//                     <p className="testIdNoDetail">{record?.tags}</p>
//                   </Col>
//                 </Row>
//               </Col>
//             );
//           })}
//         </div>
//       </Row>
//       <Pagination
//         current={currentPage}
//         pageSize={pageSize}
//         total={data?.length}
//         onChange={handlePageChange}
//         onShowSizeChange={handlePageChange}
//         style={{ marginTop: "16px", textAlign: "end", marginBottom: "16px" }}
//       />
//     </div>
//   );
// };

// CustomTable.defaultProps = {
//   onRow: null,
//   selectedRowKey: null,
//   pagination: { pageSize: 10 }, // Default page size
// };

// export default CustomTable;
import React, { useState } from "react";
import { Pagination } from "antd";
import "./style.css";
import { Col, Row } from "react-bootstrap";
import CardMenuIcon from "../../assets/svg/CardMenu";
import "../DetailCard/style.css";

const CustomTable = ({
  data,
  onRow,
  selectedRowKey,
  pagination,
  width,
  selectedPage,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pagination?.pageSize || 10);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = data.slice(startIndex, endIndex);

  const styles = {
    selectedCard: {
      backgroundColor: "#daf4e1",
    },
  };

  return (
    <div>
      <Row className="g-3">
        {paginatedData.map((record, i) => (
          <Col
            key={i}
            sm={12}
            md={width > 35 ? 6 : 12}
            lg={width > 35 ? 6 : 12}
            className="testCardStyle"
            style={record?.key === selectedRowKey ? styles.selectedCard : null}
            onClick={() => onRow && onRow(record)}
          >
            <div className="detail-card">
              <Row>
                <Col xs={3}>
                  <p className="testIdNo">Req No</p>
                </Col>
                <Col xs={3}>
                  <p className="testIdNoList">{record?.number}</p>
                </Col>
                <Col xs={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "baseline",
                    }}
                  >
                    <CardMenuIcon width={24} height={24} />
                  </div>
                </Col>
              </Row>
              {selectedPage !== "headphone" && (
                <Row>
                  <Col xs={3}>
                    <p className="testIdNo">Service Name</p>
                  </Col>
                  <Col xs={9}>
                    <p className="PersonName">{record?.name}</p>
                  </Col>
                </Row>
              )}
              <p className="borderline"></p>
              <Row>
                <Col xs={3}>
                  <p className="testIdNo">Requester Name</p>
                </Col>
                <Col xs={9}>
                  <p className="testIdNoList">
                    {selectedPage === "headphone" ? (
                      <>{record?.data?.customer?.fullname}</>
                    ) : (
                      <>{record?.fullData?.customer?.fullname}</>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <p className="testIdNo">Emirates ID</p>
                </Col>
                <Col xs={9}>
                  <p className="testIdNoDetail">
                    {selectedPage === "headphone" ? (
                      <>{record?.data?.customer?.emiratesid}</>
                    ) : (
                      <>{record?.section}</>
                    )}
                  </p>
                </Col>
              </Row>
              {selectedPage !== "headphone" && (
                <Row>
                  <Col xs={3}>
                    <p className="testIdNo">Status</p>
                  </Col>
                  <Col xs={9}>
                    <p className="testIdNoDetail">{record?.tags}</p>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        ))}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={data?.length}
        onChange={handlePageChange}
        onShowSizeChange={handlePageChange}
        style={{ marginTop: "16px", textAlign: "end", marginBottom: "16px" }}
      />
    </div>
  );
};

CustomTable.defaultProps = {
  onRow: null,
  selectedRowKey: null,
  pagination: { pageSize: 10 }, // Default page size
};

export default CustomTable;
