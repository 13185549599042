import { Col } from "react-bootstrap";
import menubar from "../../assets/images/menuBar.png";
const EmployeeNote = ({ setEmployeesNote, EmployeesNote, data }) => {
  return (
    <Col style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      {/* <div
        style={{
          color: "#3E6E4B",
          fontSize: 16,
          fontFamily: "Alexandria",
          fontWeight: "700",
          wordWrap: "break-word",
        }}
      >
        Employee notes
      </div> */}
      <div style={{ border: "none", borderRadius: "8px 8px 8px 8px" }}>
        {/* <div style={{ width: "100%" }}>
          <img
            src={menubar}
            alt="MenuBar"
            style={{
              width: "inherit",
              height: "30px",
              borderRadius: "8px 8px 0px 0px",
            }}
          />
        </div> */}
        <div style={{ borderRadius: "0px 0px 8px 8px" }}>
          <textarea
            onChange={(e) => setEmployeesNote(e.target.value)}
            value={EmployeesNote}
            disabled={
              data?.status === "APPROVED" ||
              data?.status === "REJECTED"
            }
            style={{
              width: "100%",
              height: "150px",
              border: "none",
              borderRadius: "0px 0px 8px 8px",
              background: "#F6F6F6",
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
              padding: "5px",
              cursor:
                data?.status === "APPROVED" ||
                data?.status === "REJECTED" 
                  ? "not-allowed"
                  : "auto",
            }}
          />
        </div>
      </div>
    </Col>
  );
};

export default EmployeeNote;
